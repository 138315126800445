import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-operational-readiness-signoff',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class OperationalReadinessSignoffComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Operational Readiness Sign Off is a critical step in ensuring that both internal and external teams are prepared for the successful launch of a product or update. This process includes conducting internal tests, managing external trials, and ensuring that all functions—such as sales, support, and operations—are aligned and ready. It ensures that the product is ready to be sold, supported, and maintained effectively, bridging the gap between development and the business environment.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Customer Success Team",
    "description": "Ensures the trial customers are engaged, feedback is collected, and sign-off is secured."
  },
  {
    "name": "Product Manager",
    "description": "Leads the operational readiness review process, ensuring all activities are completed and documented."
  },
  {
    "name": "Product Owner",
    "description": "Oversees the internal tests and ensures all product functionalities meet expectations."
  },
  {
    "name": "ScrumMaster",
    "description": "Facilitates coordination across development, delivery, and operational teams during the readiness phase."
  },
  {
    "name": "Release Manager",
    "description": "Manages the handover process, readiness checklists, and ensures alignment with operational teams."
  },
  {
    "name": "Delivery Team",
    "description": "Prepares for ongoing product support and ensures all operational procedures are in place."
  },
  {
    "name": "Product Support Team",
    "description": "Takes ownership of customer-facing support activities post-launch, leveraging operational materials."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Trial Customer Sign-Off",
            "description": {
              "Objective": [
                "Validate product functionality, performance, and usability with trial customers to confirm readiness for broader deployment."
              ],
              "Activity": [
                "Conduct final customer trials using a structured Pre-UAT process.",
                "Document trial customer feedback and categorize findings (e.g., critical, non-critical issues).",
                "Collaborate with customers to address critical issues and finalize the sign-off form."
              ],
              "Outcome": [
                "Signed trial customer approval form confirming the product meets their requirements."
              ]
            }
          },
          {
            "title": "KDI Delivery Team Sign-Off",
            "description": {
              "Objective": [
                "Ensure that the delivery team is fully prepared to support and maintain the product post-launch."
              ],
              "Activity": [
                "Review internal test results and address any outstanding issues.",
                "Conduct a readiness review to align on deployment and support plans.",
                "Handover deployment documentation, including checklists and troubleshooting guides, to the delivery team."
              ],
              "Outcome": [
                "Delivery team approval confirming operational readiness for product launch."
              ]
            }
          },
          {
            "title": "Deployment Handover and Internal Launch Preparation",
            "description": {
              "Objective": [
                "Prepare internal teams for the product launch and ensure seamless knowledge transfer."
              ],
              "Activity": [
                "Conduct cross-functional training sessions to educate internal teams on product functionality, support protocols, and escalation paths.",
                "Handover all operational materials, such as FAQs, user guides, and troubleshooting steps, to relevant teams.",
                "Announce the internal launch to stakeholders, including sales, support, and delivery teams."
              ],
              "Outcome": [
                "Operational teams are prepared, trained, and aligned for product launch."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Best Practices',
        items:[
          {title:'Collaborative Sign-Offs', description: 'Engage all stakeholders, including customers, early in the sign-off process to ensure alignment and buy-in.'
          },
          {title:'Structured Handover', description: 'Use templates and checklists to ensure smooth knowledge transfer to operational teams.'
          },
          {title:'Proactive Communication', description: 'Keep all teams informed about readiness progress and any last-minute updates.'
          },
          {title:'Iterative Improvement', description: 'Document lessons learned to refine future operational readiness processes.'
          }
        ]
      }
    }
  ];
}