import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-market-segmentation',
  template:
    `
  <pet-page-template [data]='pageData' [additionalContent]='additionalContent' [status]='"Draft"'></pet-page-template>
  `,
  styles: ``
})

export class MarketSegmentationComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Market segmentation is the process of using discovery insights and other relevant data to identify attractive market segments. Market segments are a collection of customers/prospects that are grouped together by 1 or more bases for segmentation. Example bases are location, geography, customer size, customer personas, etc.  what makes a market segment attractive is when there is a combination of profitable opportunities and KDI has capabilities to win. These segments can then be targeted with tailored propositions, driving new and continued product investment decisions. This process supports strategic alignment and ensures resources are directed toward high-potential opportunities. This is often referred to as ‘where to play’, ‘how to win’,’what you need’.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves the identified market segments and ensures alignment with the strategic roadmap."
  },
  {
    "name": "Product Manager",
    "description": "Leads the segmentation effort by analyzing data, defining segments, and ensuring actionable insights are derived. Works with strategy office to ensure alignment with overal strategic direction."
  },
  {
    "name": "Customer Success",
    "description": "Provides customer feedback and insights that inform segmentation decisions."
  },
  {
    "name": "Growth",
    "description": "Assesses growth opportunities and ensures alignment with marketing strategies."
  },
  {
    "name": "Strategy (CSO)",
    "description": "Provides strategic guidance and ensures segmentation supports overall business goals."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Micro Segmentation",
            "description": {
              "Objective": [
                "Identify specific customer groups with shared characteristics or behaviors to refine targeting and propositions. Where to Play."
              ],
              "Activity": [
                "Analyze discovery insights and customer data to identify trends and patterns.",
                "Conduct opportunity sizing to evaluate the potential value of each segment.",
                "Use market modeling techniques to validate assumptions and prioritize high-potential segments."
              ],
              "Outcome": [
                "Clearly defined and actionable market segments with data-driven opportunity sizing."
              ]
            }
          },
          {
            "title": "Targeting",
            "description": {
              "Objective": [
                "For an identified market segments, ensure that a compelling proposition is targeted at the appropriate personas. How to win.",
                "Ensure that this product strategy is aligned with the organization’s strategic imperatives and required investment can be justified with a business case. What you need."
              ],
              "Activity": [
                "Map each segment to strategic objectives using tools like the Strategy Cascade.",
                "Assess how segments contribute to long-term business goals and prioritize accordingly.",
                "Proactively make strategic decisions on which segments are of less attractiveness/importance or should not be pursued based on alignment with strategic imperatives."
              ],
              "Outcome": [
                "Strategically aligned market segments ready for product and investment focus. Provides overarching roadmap investment themes.",
                "•	It’s equally important to have identified:  Where NOT to play,  How NOT  to win, and what we DON’T need."
              ]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Micro Segmentation', description:
            {
              'Objective': ['A SaaS product targeting manufacturing industries could identify micro-segments like "mid-sized manufacturers in the automotive sector" or "large-scale manufacturers in renewable energy equipment."'],
              'Activity': [],
              'Outcome': ['Opportunity sizing reveals that renewable energy manufacturers show higher growth potential, guiding prioritization.']
            }
          },
          {
            title: 'Strategic Imperatives Mapping', description:
            {
              'Objective': ['Aligning the renewable energy segment with corporate objectives such as "Win New Markets" and "Expand Key Verticals" helps ensure focus on strategic growth areas.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    
  ];
  public additionalContent = [
    {
      type: 'tips',
      title: 'Tips',
      value: 'Effective market segmentation is the foundation of targeted strategies—invest time in understanding customer segments to align value propositions effectively.'
    },
    {
      type: 'attachments',
      title: 'Templates',
      value: [
        {
          name: 'Market Opportunity Size',
          url: 'assets/media/templates/2409 Market Opportunity Size.pptx'
        },
        {
          name: 'Segmentation Market Model Mapping',
          url: 'assets/media/templates/2409 Segmentation Market Model Mapping.pptx'
        },
        {
          name: 'Segmentation Customer Opportunity Mapping',
          url: 'assets/media/templates/2409 Segmentation Customer Opportunity Mapping.pptx'
        },
        {
          name: 'Segmentation Customer Loyalty Mapping',
          url: 'assets/media/templates/2409 Segmentation Customer Loyalty Mapping.pptx'
        }
      ]
    }, 
    {
      type: 'optionalContent',
      title: 'Benefits and Repercussions',
      value: [{
        key: 'Benefits',
        data: ['Enables tailored messaging and solutions, driving higher customer engagement and conversion rates.', 'Optimizes resource allocation by focusing efforts on high-potential market segments.']
      },
      {
        key: 'Repercussions',
        data: ['Poor segmentation risks misaligned product offerings and diluted market presence.', 'Overlooking evolving customer needs can lead to missed opportunities and competitive disadvantage.']
      }]
    }
  ];
}