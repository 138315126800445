<div class="d-flex justify-content-center">

<div class="beam-wrapper mt-20">
  
  <!-- Loop through the iconData array to render iconData -->
  <div *ngFor="let item of iconData; let i = index" class="icon" [attr.id]="item.name" [ngStyle]="getGridPosition(i)"  [ngbPopover]="item.name" triggers="mouseenter:mouseleave">
    <!-- <i [ngClass]="[item.icon, 'fs-2', 'circle-icon']"></i> -->
     
    <div class="fs-2 circle-icon" [ngStyle]="{'background-color': getIndexColors(i)}" >
    {{ getFirstTwoLetters(item.name) }}
</div>
  </div>

  <!-- Center Icon -->
  <div class="center">
    <div class="icon" [attr.id]="centerTitle">
      <div class="fs-2 circle-icon" [ngStyle]="{'background-color': getIndexColors(0)}" >
        {{ centerTitle }}
    </div>
    </div>
  </div>

  <!-- Dynamically render connection beams for each icon -->
  <ng-container *ngFor="let item of iconData; let i = index">
    <!-- <om-connection-beam
     [fromRef]="getHTMLElementById(item.name)"
      [toRef]="getHTMLElementById(centerTitle)"
      [curvature]="calculateCurvature(i)"
      [reverse]="shouldReverse(i)"
      [pathWidth]="2">
    </om-connection-beam> -->
  </ng-container>
</div>
</div>