import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-product-launch-promotion',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductLaunchPromotionComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Product Launch and Promotion process involves strategizing, planning, and executing marketing campaigns that effectively introduce and promote products to target audiences. This includes a mix of thought leadership, lead generation, customer acquisition, and retention strategies. By aligning budgets, resources, and tracking performance, this process ensures maximum impact and measurable results for every product launch. It serves as the critical bridge between the product\'s development and its market success.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product Manager",
    "description": "Approves the launch strategy and ensures alignment with product goals."
  },
  {
    "name": "Product Marketing",
    "description": "Leads the creation and execution of marketing campaigns and collateral."
  },
  {
    "name": "Customer Success",
    "description": "Provides customer insights and supports retention-focused campaigns."
  },
  {
    "name": "Growth Team",
    "description": "Executes lead-generation strategies and tracks campaign performance metrics."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Developing the Product Launch Plan",
            "description": {
              "Objective": [
                "Define a comprehensive launch plan aligned with the product's value proposition and strategic goals."
              ],
              "Activity": [
                "Collaborate with stakeholders to define launch objectives and key deliverables.",
                "Outline launch phases, milestones, and timelines.",
                "Allocate budgets and resources to ensure successful execution."
              ],
              "Outcome": [
                "A detailed and actionable product launch plan."
              ]
            }
          },
          {
            "title": "Crafting Marketing Campaigns",
            "description": {
              "Objective": [
                "Design and execute marketing campaigns that generate awareness, leads, and engagement."
              ],
              "Activity": [
                "Leverage value propositions to create messaging tailored to target audiences.",
                "Develop thought leadership content, including blogs, white papers, and webinars.",
                "Implement lead-generation tactics such as email campaigns, landing pages, and paid ads."
              ],
              "Outcome": [
                "A suite of targeted campaigns designed to drive measurable results."
              ]
            }
          },
          {
            "title": "Coordinating Cross-Functional Activities",
            "description": {
              "Objective": [
                "Ensure all teams are aligned and contributing to the success of the product launch."
              ],
              "Activity": [
                "Engage customer success and sales teams with training and sales enablement tools.",
                "Distribute marketing collateral, including product books, battlecards, and elevator pitches.",
                "Coordinate with growth and product marketing teams to align promotional efforts."
              ],
              "Outcome": [
                "Cross-functional alignment that enhances the effectiveness of launch activities."
              ]
            }
          },
          {
            "title": "Tracking and Measuring Campaign Effectiveness",
            "description": {
              "Objective": [
                "Monitor and analyze the performance of launch campaigns to optimize results."
              ],
              "Activity": [
                "Set KPIs and track metrics like reach, engagement, conversion, and retention.",
                "Use analytics tools to gather insights and identify areas for improvement.",
                "Iterate on campaigns based on performance data to maximize outcomes."
              ],
              "Outcome": [
                "A data-driven understanding of campaign success and actionable insights for improvement."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Value Proposition Document', description: 'A concise statement capturing the product’s key benefits and relevance to customers.'
          },
          {title:'Marketing Communication Plan', description: 'A calendar and strategy document for distributing content across channels.'
          },
          {title:'Sales Enablement Tools', description: 'Product books, battlecards, and elevator pitches.'
          },
          {title:'Marketing Campaigns', description: 'Thought leadership content, email campaigns, and social media posts.'
          },
          {title:'Performance Reports', description: 'Metrics and analytics from campaigns to measure effectiveness.'
          }
        ]
      }
    }
  ];
}