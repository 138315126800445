<div class="container-fluid ">
  <div class="row">
    <!-- First Parent Div (70%) -->
    <div class="col-9">
      <div class="container-fluid  min-vh-100 d-flex flex-column">
        <!-- Main Content -->
        <div class="container py-4 flex-grow-1">
          <div class="page-title-container">
            <!-- Status-->
            <div *ngIf="status" class="status-tag-container">
              <span [ngClass]="{'badge-draft': status === 'Draft', 'badge-published': status === 'Published'}"
                class="status-tag">{{ status }}</span>
            </div>
          </div>
          <!-- Iterate over data -->
          <div *ngFor="let item of data; let i = index">

            <!-- Introduction Section -->
            <section *ngIf="item?.type === 'intro'" class="mt-5" [id]="'intro-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.mainText }}</span>
              <p class="opacity-65 mt-5">{{ item?.value?.subText }}</p>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>
            <!-- List Items Section updated to handle \n tag-->
            <section *ngIf="item?.type === 'listItem'" class="mt-10" [id]="'listItem-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
              <div *ngFor="let listItem of item?.value?.items"
                class="d-flex align-items-center gap-4 px-3 py-2 mb-1 mt-5">
                <div class="flex-column">
                  <p class="font-weight-medium mb-0 fs-5">{{ listItem?.name || listItem?.title }}</p>
                  <p class="opacity-65 medium">{{listItem?.description}}</p>
                </div>
              </div>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>
            <!-- List Items Section updated to handle \n tag-->
            <section *ngIf="item?.type === 'tileItem'" class="mt-10" [id]="'listItem-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>

              <!-- Create a wrapper for list items -->
              <div class="cards-wrapper">
                <div *ngFor="let listItem of item?.value?.items; let j = index"
                  [ngStyle]="{ 'background-color': colors[j % colors.length] }" class="square-card">

                  <div class="card-content">
                    <p class="font-weight-medium mb-0 fs-5 card-title ">{{ listItem?.name || listItem?.title }}</p>
                    <p class="opacity-75 medium fs-7">{{ listItem?.description }}</p>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>



            <!-- Multi Bullet List Items Section -->
            <!-- Multi Bullet List Items Section -->
            <section *ngIf="item?.type === 'multibulletList'" class="mt-10" [id]="'multibulletList-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
              <div *ngFor="let listItem of item?.value?.items"
                class="d-flex align-items-center gap-4 px-3 py-2 mb-1 mt-5">
                <div class="flex-column">
                    <div class="d-flex align-items-center">                     
                    <span class="bullet bg-dark h-2px w-15px opacity-75"></span>
                    <label class="ms-2 font-weight-medium mb-0 fs-5 ">{{ listItem?.name || listItem?.title }}</label>
                  </div>
                  <!-- List Item Description -->
                  <ul class="opacity-65 medium">
                    <li *ngFor="let bullet of listItem?.description" class="mb-1">
                      <!-- Italicize "Example" and its value -->
                      <span [innerHTML]="bullet.includes('Example:') ? '<i>' + bullet + '</i>' : bullet"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>

            <!-- Hyperlink Section -->
            <section *ngIf="item?.type === 'hyperlink'" class="mt-5" [id]="'hyperlink-' + i">
              <!-- Section Heading -->
              <h3 class="section-heading">{{ item?.value?.heading }}</h3>

              <!-- Loop Through Links -->
              <div *ngFor="let link of item?.value?.links" class="d-flex align-items-center mt-3">
                <span class="font-weight-bold link-title me-2">{{ link?.title }}</span>
                <a [href]="link?.url" target="_blank" rel="noopener noreferrer"
                  class="d-flex align-items-center text-decoration-none link-icon">
                  <i class="bi bi-link-45deg me-1 text-primary"></i> <!-- Bootstrap default blue icon -->
                  {{ link?.url }}
                </a>
              </div>

              <!-- Divider -->
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>

            <!-- superList Section -->
            <section *ngIf="item?.type === 'superListItem'" class="mt-10" [id]="'superListItem-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
              <!-- Sub Title -->
              <p *ngIf="item?.value?.subTitle" class="opacity-65 medium mt-2">{{ item?.value?.subTitle }}</p>
              <ul class="nav nav-pills mt-5" id="superListTabs" role="tablist">
                <li class="nav-item" role="presentation" *ngFor="let listItem of item?.value?.items; let idx = index">
                  <button 
                    class="nav-link btn btn-outline-primary" 
                    [class.active]="idx === 0" 
                    [id]="'tab-' + idx" 
                    data-bs-toggle="pill" 
                    [attr.data-bs-target]="'#content-' + i + idx" 
                    role="tab" 
                    [attr.aria-controls]="'content-' + idx"
                    [attr.aria-selected]="idx === 0">
                    {{ listItem?.name || listItem?.title }}
                  </button>
                </li>
              </ul>
              
              
              <div class="tab-content mt-3" id="superListTabsContent">
                <div *ngFor="let listItem of item?.value?.items; let idx = index" class="tab-pane fade"
                  [class.show]="idx === 0" [class.active]="idx === 0" [id]="'content-' + i + idx" role="tabpanel"
                  [attr.aria-labelledby]="'tab-' + idx">
                  <div class="d-flex align-items-center gap-4 px-3 py-2 mb-1 mt-5">
                    <div class="flex-column">
                      <ng-container *ngFor="let key of getKeys(listItem?.description)">
                        <ng-container *ngIf="getArray(listItem?.description, key).length > 0">
                          <p *ngIf="key !== ''" class="opacity-65 medium">{{ key }}:</p>
                          <ul>
                            <li *ngFor="let data of getArray(listItem?.description, key)"
                              class="opacity-65 medium mb-2">{{ data }}</li>
                          </ul>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>


            <!-- Checklist Items Section -->
            <section *ngIf="item?.type === 'checkListItem'" class="mt-10" [id]="'checkListItem-' + i">
                <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
                <div class="custom-check mt-5" *ngFor="let checkItem of item?.value?.items" style="margin-left: 1rem;">
                <li class="d-flex align-items-center py-2">
                  <span class="bullet bullet-dot bg-dark h-6px w-6px opacity-50"></span>
                  <label class="opacity-65 ms-2" for="prerequisite{{ checkItem?.id }}">{{ checkItem?.text }}</label>
                </li>
                </div>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>

            <!-- Attachments Section 
            <section *ngIf="item?.type === 'attachment'" class="mt-10" [id]="'attachment-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
              <div class="d-flex justify-content-center mt-5 mb-10">
                <img *ngFor="let attachment of item?.value?.items" class="img-fluid rounded" [src]="attachment?.src"
                  alt="Media Attachment" />
              </div>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>-->

            <!-- New Attachments Section by Anshul for multiple images and support for video -->
            <section *ngIf="item?.type === 'attachment'" class="mt-10" [id]="'attachment-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>

              <!-- Carousel -->
              <div id="carousel-{{ i }}" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div *ngFor="let attachment of item?.value?.items; let idx = index" class="carousel-item"
                    [class.active]="idx === 0">
                    <!-- Clickable Image -->
                    <a [href]="attachment?.src" target="_blank" rel="noopener noreferrer">
                      <img class="d-block w-100 img-fluid rounded" [src]="attachment?.src"
                        [alt]="attachment?.description" />
                    </a>
                  </div>
                </div>

                <!-- Controls -->
                <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carousel-' + i"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#carousel-' + i"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </section>

            <!-- File Attachments Section -->
            <!-- File Attachments Section -->
            <section *ngIf="item?.type === 'FileAttachment'" class="mt-10 mb-4" [id]="'FileAttachment-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>

              <!-- File Attachment Carousel with Specific Class -->
              <div id="carousel-{{ i }}" class="carousel slide file-carousel mt-3" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div *ngFor="let attachment of item?.value?.items; let idx = index" class="carousel-item"
                    [class.active]="idx === 0">
                    <!-- Icon and File Name Aligned Vertically -->
                    <div class="d-flex flex-column align-items-center px-3">
                      <i class="bi bi-paperclip text-primary" style="font-size: 3rem;"></i>
                      <a [href]="attachment?.src" target="_blank" rel="noopener noreferrer"
                        class="text-decoration-none mt-2" style="font-size: 1.2rem; color: #f8f9fa;">
                        {{ attachment?.name }}
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Controls -->
                <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carousel-' + i"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#carousel-' + i"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </section>

            <!-- FAQ Section -->
            <section *ngIf="item?.type === 'faq'" class="mt-5" [id]="'faq-' + i">
              <!-- Section Heading -->
              <h3 class="section-heading">{{ item?.value?.heading }}</h3>

              <!-- Accordion for FAQs -->
              <div class="accordion mt-3" [id]="'accordion-' + i">
                <div *ngFor="let faq of item?.value?.questions; let idx = index" class="accordion-item">
                  <!-- Question -->
                  <h2 class="accordion-header" [id]="'heading-' + i + '-' + idx">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse-' + i + '-' + idx" aria-expanded="false"
                      [attr.aria-controls]="'collapse-' + i + '-' + idx">
                      {{ faq?.question }}
                    </button>
                  </h2>

                  <!-- Answer -->
                  <div [id]="'collapse-' + i + '-' + idx" class="accordion-collapse collapse"
                    [attr.aria-labelledby]="'heading-' + i + '-' + idx" [attr.data-bs-parent]="'#accordion-' + i">
                    <div class="accordion-body" [innerHTML]="faq?.answer"></div>
                  </div>
                </div>
              </div>

              <!-- Divider -->
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>

            <!-- coding section-->
            <section *ngIf="item?.type === 'coding'" class="mt-10" [id]="'coding-' + i">
              <span class="font-weight-bold intro-title">{{ item?.title }}</span>
              <div class="container my-5">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between align-items-center mb-2">
                    <ul class="nav nav-tabs" id="languageTabs" role="tablist">
                      <li class="nav-item" role="presentation" *ngFor="let tab of item?.value; let idx = index">
                        <a class="nav-link" [class.active]="idx === 0" [id]="tab.language + '-tab'" data-bs-toggle="tab"
                          [href]="'#' + tab.language" role="tab" [attr.aria-controls]="tab.language"
                          [attr.aria-selected]="idx === 0" (click)="setIndex(idx)">{{ tab.language | uppercase }}</a>
                      </li>
                    </ul>
                    <button class="btn btn-sm btn-outline-secondary copy-btn" (click)="copyCode()">
                      <i class="bi bi-clipboard"></i> Copy
                    </button>
                  </div>
                  <div class="col-12 col-md-12"></div>
                  <!-- Code Block -->
                  <div class="tab-content" id="languageTabsContent">
                    <div *ngFor="let tab of item?.value; let idx = index" class="tab-pane fade" [class.show]="idx === 0"
                      [class.active]="idx === 0" [id]="tab.language" role="tabpanel"
                      [attr.aria-labelledby]="tab.language + '-tab'">
                      <pre class="bg-secondary p-1 rounded">
                        <span [id]="'codeBlock' +  idx">{{ tab.code }}</span>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>

            <!-- Connected Beam Segment -->
            <section *ngIf="item?.type === 'beamItem'" class="mt-10" [id]="'beamItem-' + i">
              <span class="font-weight-bold intro-title">{{ item?.value?.title }}</span>
              <pet-connected-beam [iconData]="item?.value?.items"
                [centerTitle]="item?.value?.title"></pet-connected-beam>
              <ng-container *ngIf="i !== data.length - 1">
                <div class="wrapper">
                  <div class="divider div-transparent"></div>
                </div>
              </ng-container>
            </section>
          </div>
        </div>
      </div>
    </div>



    <!-- Second Parent Div (30%) -->
    @if(additionalContent?.length > 0){
    <div class="col-2 d-flex justify-content-center">
      <div id="mySidebar" class="sidebar">
        <h1 class="fs-1 opacity-75 sidebar-title">Additional Content
          <div class="wrapper">
            <div class="divider div-transparent"></div>
          </div>
        </h1>
        <!-- Loop over the additionalContent array -->
        <div *ngFor="let item of additionalContent">

          <!-- Tips Section -->
          <div class=" p-5 addition-content" *ngIf="item.type === 'tips'"> <i
              class="fa-solid fa-lightbulb fs-3 me-2"></i>
            <span class="fs-4">{{ item.title }}</span>
            <div class="tip-section mt-4">
              <p class="opacity-65">{{ item.value }}</p>
            </div>
          </div>

          <!-- Attachments Section -->
          <div class="p-4 mt-5  addition-content" *ngIf="item.type === 'attachments'">
            <div class="">
              <i class="fa-solid fa-paperclip fs-3  me-2"></i>
              <span class="fs-4">{{ item.title }}</span>
            </div>
            <div class="tip-section mt-4">
              <ul>
                <li class="opacity-65" *ngFor="let attachment of item.value">
                  <a [href]="attachment.url" target="_blank">{{ attachment.name }}</a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Optional Content (Benefits and Challenges) -->
          <div class=" p-5 mt-5  addition-content" *ngIf="item.type === 'optionalContent'">
            <div class="">
              <i class="fa-solid fa-yin-yang fs-3  me-2"></i>
              <span class="fs-4">{{ item.title }}</span>
            </div>

            <!-- Tabs for Benefits and Challenges -->
            <ul class="nav nav-tabs  mt-5" id="myTab" role="tablist">
              <li class="nav-item opacity-65" role="presentation">
                <a class="nav-link active" id="benefits-tab" data-bs-toggle="tab" href="#benefits" role="tab"
                  aria-controls="benefits" aria-selected="true">Benefits</a>
              </li>
              <li class="nav-item opacity-65" role="presentation">
                <a class="nav-link" id="challenges-tab" data-bs-toggle="tab" href="#challenges" role="tab"
                  aria-controls="challenges" aria-selected="false">Repercussions</a>
              </li>
            </ul>

            <div class="tab-content mt-5" id="myTabContent">
              <!-- Benefits Tab -->
              <div class="tab-pane fade show active" id="benefits" role="tabpanel" aria-labelledby="benefits-tab">
                <ul>
                  <li *ngFor="let benefit of item.value[0].data" class="opacity-65">{{ benefit }}</li>
                </ul>
              </div>

              <!-- Challenges Tab -->
              <div class="tab-pane fade" id="challenges" role="tabpanel" aria-labelledby="challenges-tab">
                <ul>
                  <li *ngFor="let challenge of item.value[1].data" class="opacity-65">{{ challenge }}</li>
                </ul>
              </div>
            </div>
          </div>

        </div> <!-- End of *ngFor loop -->
      </div>
    </div>

    }
  </div>
</div>