import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-technical-exploration',
  template:
    `
  <pet-page-template [data]='pageData' [additionalContent]='additionalContent' [status]='"Draft"'></pet-page-template>
  `,
  styles: ``
})

export class TechnicalExplorationComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Sponsoring or running technical discovery processes. Sometimes co-collaborating with customers and prospects. Investigate the evolving technology trends to understand what may support external or internal innovation opportunities.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  { "name": "Chief Product Officer (CPO)", "description": "Approves and sponsors technical exploration initiatives." },
  { "name": "Chief Technology Officer (CTO)", "description": "Leads technical discovery processes and provides strategic guidance on technology trends." },
  { "name": "Product VP", "description": "Supports collaboration with internal and external stakeholders, ensuring alignment with product strategy." },
  { "name": "Architect", "description": "Assesses technical feasibility and designs innovative solutions." },
  { "name": "UX Lead", "description": "Ensures user-centric design principles are considered during exploration." },
  { "name": "CSIO / Security Lead", "description": "Evaluates security implications and ensures compliance with industry standards." }
]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Hypothesis Development",
            "description": {
              "Objective": [
                "Define clear hypotheses for how emerging technologies could address business needs or customer pain points."
              ],
              "Activity": [
                "Use Lean Canvas to outline the problem, solution, key metrics, and unique value proposition.",
                "Engage with cross-functional teams to brainstorm potential technical solutions."
              ],
              "Outcome": [
                "A set of defined hypotheses ready for exploration and validation."
              ]
            }
          },
          {
            "title": "Technology Trend Investigation",
            "description": {
              "Objective": [
                "Analyze evolving technology trends to assess relevance and impact on the business."
              ],
              "Activity": [
                "Conduct market research and review technology trend reports.",
                "Engage with industry analysts, thought leaders, and technology vendors.",
                "Evaluate comparative technologies to assess feasibility and differentiation."
              ],
              "Outcome": [
                "Documented insights on relevant technologies and potential opportunities."
              ]
            }
          },
          {
            "title": "Customer Co-Collaboration",
            "description": {
              "Objective": [
                "Validate hypotheses by engaging with customers and prospects."
              ],
              "Activity": [
                "Host workshops and brainstorming sessions to explore technical concepts.",
                "Develop low-fidelity prototypes or mockups to gather feedback.",
                "Document findings and refine technical concepts based on user input."
              ],
              "Outcome": [
                "Validated use cases and actionable feedback from customers."
              ]
            }
          },
          {
            "title": "Business Case Development",
            "description": {
              "Objective": [
                "Translate technical findings into a compelling business case for strategic decision-making."
              ],
              "Activity": [
                "Leverage Lean Canvas to articulate value, risks, and success metrics.",
                "Prepare cost-benefit analysis and risk assessment.",
                "Secure stakeholder buy-in for investments or further development."
              ],
              "Outcome": [
                "A comprehensive business case supporting technical investment decisions."
              ]
            }
          }
        ]
      }
    },
    {
      type:'listItem',
      value:{
        title:'Artifacts & Deliverables',
        items:[
          {name:'Lean Canvas Hypotheses', description:'Clearly defined problem-solution pairs and success metrics.'},
          {name:'Technology Trend Reports', description:'Documented insights on emerging technologies.'},
          {name:'Prototypes', description:'Early-stage mockups for testing and validation.'},
          {name:'Business Case Document', description:'Comprehensive justification for technical investments.'}
          
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Exploring AI for Predictive Analytics', description:
            {
              'Objective': ['Testing AI algorithms to predict machine failures and reduce downtime.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Assessing Cloud Platforms for Scalability', description:
            {
              'Objective': ['Comparing cloud solutions for high-demand scenarios.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    
  ];
  public additionalContent = [
    {
      type: 'tips',
      title: 'Tips',
      value: 'While filling out your Lean Canvas, start with the Problem and Customer Segments boxes first. This helps ground your exploration by focusing on the core issue you are solving and who you are solving it for.'
    },
    {
      type: 'attachments',
      title: 'Templates',
      value: [
        {
          name: 'Lean Canvas Template',
          url: 'assets/media/templates/2501 Lean Canvas.pptx'
        },
        {
          name: 'Lean Canvas Work Board',
          url: 'https://lucid.app/lucidspark/7b73c367-e94d-4592-a28d-6c9ecb4d2309/edit?viewport_loc=-2764%2C2304%2C3980%2C2043%2C0_0&invitationId=inv_4111653e-3ba3-4a7f-94f3-c7b7f6271f9b'
        }
      ]
    }, 
    {
      type: 'optionalContent',
      title: 'Benefits and Repercussions',
      value: [{
        key: 'Benefits',
        data: ['Unveils new capabilities or improvements that can enhance product competitiveness.', 'Builds a foundation for scalable and future-ready solutions by validating technical feasibility early.']
      },
      {
        key: 'Repercussions',
        data: ['Neglecting exploration can lead to outdated technology stacks and missed market opportunities.', 'Insufficient collaboration with customers or stakeholders may result in solutions misaligned with real-world needs.']
      }]
    }
  ];
}