import { Component } from '@angular/core';

@Component({
  selector: 'pet-training-modules',
  template: `
    <pet-page-template [data]='pageData'  [status]='"Draft"' ></pet-page-template>
  `,
  styles: []
})

export class TrainingModulesComponent {
  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The ADAPT Training Modules are designed to equip teams with comprehensive knowledge of the ADAPT framework, tools, and best practices. These modules aim to empower individuals with the skills needed to drive impactful results. We are actively working on developing and refining these training modules to ensure they are robust, engaging, and aligned with your needs. Stay tuned for more updates as we continue to build this resource. Detailed module content and schedules will be shared soon.'
      }
    }
  ];
}
