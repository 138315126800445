import { Component } from '@angular/core';

@Component({
  selector: 'pet-version-history',
  template: `<pet-page-template [data]='pageData' [status]='"Draft"'></pet-page-template>`,
  styles: []
})

export class VersionHistoryComponent {
  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'This section will provide a detailed log of changes, updates, and enhancements made to the ADAPT framework over time. The Version History helps maintain transparency and ensures that all stakeholders are informed about the evolution of processes and tools. We are in the process of compiling this information. Please stay tuned for updates.'
      }
    }
  ];
}
