import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-product-readiness-qa-signoff',
  template:
    `
  <pet-page-template [data]='pageData' [additionalContent]='additionalContent'></pet-page-template>
  `,
  styles: ``
})

export class ProductReadinessQaSignoffComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Product Readiness (QA) Signoff process ensures that the product is rigorously tested and validated before moving into operations trials or internal launches. This stage involves comprehensive quality assurance checks and cross-functional collaboration to confirm that the product meets all requirements and is fit for deployment and customer trials. The goal is to deliver a feature or product that is ready to be deployed and trialed with select customers, ensuring a smooth transition to operational readiness.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
        items: [
          { name: 'Product Manager/Owner*', description: 'Confirms that all acceptance criteria have been met and signs off on the product from a business perspective.' },
          { name: 'QA Lead', description: 'Oversees the testing process, ensures thorough coverage, and manages the resolution of defects.' },
          { name: 'Development Team', description: 'Implements and tests features, fixes defects, and ensures code adheres to quality standards.' },
          { name: 'Cross-Functional Stakeholders', description: 'Participate in final readiness reviews to provide feedback and validate that the product meets customer and business requirements.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'All features and fixes have passed automated and manual testing.' },
          { id: 2, text: 'Completion of all regression, performance, and security tests.' },
          { id: 3, text: 'Updated documentation, including release notes, user guides, and support materials.' },
          { id: 4, text: 'Approval from key stakeholders on readiness criteria.' }
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Process Steps',
        items: [
          {
            title: 'Quality Assurance/Testing', description:
            {
              'Objective': ['Execute comprehensive testing to validate that the product is ready for deployment.'],
              'Activity': ['Perform functional testing to ensure features work as expected.',
                'Conduct regression testing to confirm new changes haven’t broken existing functionality.',
                'Execute performance testing to verify that the product meets performance benchmarks.',
                'Conduct security testing to ensure there are no vulnerabilities.'],
              'Outcome': ['A validated and high-quality product that is free of critical defects.']
            }
          },
          {
            title: 'Cross-Functional Readiness Review', description:
            {
              'Objective': ['Collaborate with cross-functional teams to verify the product is ready for an operational trial.'],
              'Activity': ['Conduct a readiness review with stakeholders from product management, engineering, sales, and support.',
                'Review test results, outstanding issues, and deployment plans.',
                'Address feedback from cross-functional teams and incorporate any last-minute adjustments if necessary.'],
              'Outcome': ['Consensus on product readiness and approval to move forward.']
            }
          },
          {
            title: 'Product Readiness Checklist', description:
            {
              'Objective': ['Use a checklist to ensure all critical aspects of product readiness are covered.'],
              'Activity': ['All high-priority bugs have been resolved or accepted as known issues.',
                'User documentation is complete and available for internal or customer use.',
                'Release notes and training materials have been reviewed and approved.',
                'Monitoring and alerting configurations are in place for the deployment phase.'],
              'Outcome': ['A comprehensive checklist confirming all readiness criteria are met.']
            }
          },
          {
            title: 'Internal Launch Preparation', description:
            {
              'Objective': ['Prepare for an internal launch to gather initial feedback and ensure a smooth transition.'],
              'Activity': ['Announce the internal launch to relevant teams and schedule any necessary training sessions.',
                'Monitor product performance and collect feedback from internal users.',
                'Use the internal trial to identify any final issues before broader deployment.'],
              'Outcome': ['A successfully launched product internally, ready for external customer trials.']
            }
          }
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Example 1', description:
            {
              'Objective': ['A product feature passes all tests but has a minor known issue that is documented and accepted by the Product Owner before deployment.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Example 2', description:
            {
              'Objective': ['During the cross-functional review, the support team requests additional training materials, which are created before the product is internally launched.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Tools / Resources / Templates',
        items: [
          { id: 1, text: 'Product Readiness Checklist: A comprehensive checklist to ensure all readiness criteria are met.' }
        ]
      }
    },

  ];

  public additionalContent = [
    {
      type: 'tips',
      title: 'Tips',
      value: 'Ensure that all stakeholders are aligned on the readiness criteria and that any outstanding issues are documented and accepted before moving forward.'
    },
    {
      type: 'optionalContent',
      title: 'Benefits and Repercussions',
      value: [{
        key: 'Benefits',
        data: ['Ensures that the product meets quality standards before deployment.', 'Facilitates cross-functional collaboration and alignment on readiness criteria.']
      },
      {
        key: 'Repercussions',
        data: ['Balancing the need for thorough testing with the desire to move quickly to deployment.', 'Managing last-minute changes or feedback that could impact the launch timeline.']
      }]
    }
  ];
}