import { Component, Input } from '@angular/core';
import { RAD_PERSONA_DICTIONARY } from 'src/app/app.constants';

@Component({
  selector: 'pet-connected-beam',
  templateUrl: './connected-beam.component.html',
  styleUrl: './connected-beam.component.scss'
})
export class ConnectedBeamComponent {
  @Input() iconData:any
  @Input() centerTitle: string;
  public dataDict  = RAD_PERSONA_DICTIONARY;

  // Calculate curvature based on the index considering the midpoint
  calculateCurvature(index: number): number {
    const midPoint = Math.floor(this.iconData.length / 2);
    const offset = index < midPoint ? index - midPoint + 2 : index - midPoint  - 1;
    return offset * 120 / this.iconData.length;
  }

  // Determine if the beam should be reversed based on the index considering the midpoint
  shouldReverse(index: number): boolean {
    const midPoint = Math.floor(this.iconData.length / 2);
    return index >= midPoint;  // Reverse for iconData after the midpoint
  }
  getHTMLElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    if (!element) {
      throw new Error(`Element with id ${id} not found`);
    }
    return element;
  }
  getGridPosition(index: number) {
    const midPoint = Math.floor(this.iconData.length / 2);
    if (index < midPoint) {
      return {
      'grid-column': '1',
      'grid-row': (index + 1).toString()
      };
    } else if (index >= midPoint) {
      return {
      'grid-column': '4',
      'grid-row': (index - midPoint + 1).toString()
      };
    }
    return {};
  }
  // Function to get random colors (just for demo)
  getIndexColors(index: number): string {
    const colors = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#277da1', '#6a4c93'];
    return colors[index % colors.length];
  }
  getFirstTwoLetters(text: string): string {
    const foundKey = Object.keys(this.dataDict).find(key => text.toLowerCase().includes(key.toLowerCase()));
    if (foundKey) {
      return this.dataDict[foundKey];
    } else {
      return text.slice(0, 2).toUpperCase();
    }
  }
  getArrowPosition(index: number) {
    const position = this.getGridPosition(index);
    const transformValue = this.shouldReverse(index) ? 'translate(75%, 25%)' : 'translate(0%, 25%)';
    return {
      ...position,
      position: 'relative',
      transform: transformValue
    };
  }
}
