import { Component } from '@angular/core';

@Component({
  selector: 'pet-adapt-certifications',
  template: `
    <pet-page-template [data]="pageData"  [status]='"Draft"'></pet-page-template>
  `,
  styles: []
})
export class AdaptCertificationsComponent {

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'We are thrilled to announce that the ADAPT Certifications program is in development! This initiative is designed to empower teams and individuals with the skills and knowledge necessary to excel in their roles while fostering alignment with the ADAPT framework.'
      }
    },
    {
      type: 'multibulletList',
      value: {
        title: 'Overview',
        items: [
          {
            name: 'The certification program will include comprehensive modules tailored to various roles and lifecycle stages in the ADAPT framework, covering topics such as:',
            description: [
              'Agile Fundamentals',
              'Empowered Product Teams (EPT) Basics and Advanced Practices',
              'ADAPT Lifecycle Stages and Processes',
              'Role-Specific Expertise (e.g., Product Manager, Tech Lead, Scrum Master)',
              'Metrics & KPIs Mastery'
            ]
          }
        ]
      }
    },
    {
      type: 'intro',
      value: {
        mainText: 'Stay Tuned for Updates',
        subText: 'We are working diligently to finalize the program content, structure, and delivery mechanism. Our goal is to offer a flexible, engaging, and valuable certification experience for all participants. Once the program is ready, we will share details about certification levels and pathways, detailed course content, access to training materials and assessments, information on how to earn and showcase your certifications.'
      }
    }
  ];
}
