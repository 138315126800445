/* Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
/* Components */

import { LoaderComponent } from "src/app/widgets/loader/loader.component";
import { ConnectedBeamComponent } from './connected-beam/connected-beam.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ConnectedBeamComponent,
    LoaderComponent,
  ],
  imports: [
    NgbPopoverModule,
    CommonModule,
    InlineSVGModule,
  ],
  exports: [
    ConnectedBeamComponent,
    LoaderComponent,
  ],
})
export class WidgetsModule { }
