import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConstant, RAD_PERSONA_DICTIONARY } from 'src/app/app.constants';
import { THEME } from 'src/app/app.constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'pet-summary-menu-view',
  templateUrl: './summary-menu-view.component.html',
  styleUrl: './summary-menu-view.component.scss'
})
export class SummaryMenuViewComponent {
  @Input() summaryMenu: any;
  public selectedOutcomeIndex: number = 0;
  public selectedTab: 'techniques' | 'processes' = 'techniques'; 
  public showAllApprovers: boolean = false;
  public showAllResponsibles = false;
  public showAllDelivers = false;
  public theme = THEME;
  public dataDict  = RAD_PERSONA_DICTIONARY;

  // New property for sanitized description
  public sanitizedDescription: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}
    // Add this lifecycle hook to sanitize the description
    ngOnChanges() {
      if (this.summaryMenu?.description) {
        this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.summaryMenu.description);
      }
    }

  // Select an outcome
  selectOutcome(index: number) {
    this.selectedOutcomeIndex = index;
    this.selectedTab = 'techniques'; // Reset to techniques tab when outcome changes
  }

  // Select a tab (either 'techniques' or 'processes')
  selectTab(tab: 'techniques' | 'processes') {
    this.selectedTab = tab;
  }

  getFirstTwoLetters(text: string): string {
    const foundKey = Object.keys(this.dataDict).find(key => text.toLowerCase().includes(key.toLowerCase()));
    if (foundKey) {
      return this.dataDict[foundKey];
    } else {
      return text.slice(0, 2).toUpperCase();
    }
  }
  // Function to get random colors (just for demo)
  getIndexColors(index: number): string {
    const colors = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#277da1', '#6a4c93'];
    return colors[index % colors.length];
  }

  // Toggle visibility of additional approvers
  toggleApproverVisibility() {
    this.showAllApprovers = !this.showAllApprovers;
  }

  // Toggle visibility of additional responsibles
  toggleResponsibleVisibility() {
    this.showAllResponsibles = !this.showAllResponsibles;
  }

  // Toggle visibility of additional deliverables
  toggleDeliverVisibility() {
    this.showAllDelivers = !this.showAllDelivers;
  }
  getSidebarHeight(): string {
    return this.summaryMenu?.infoLevel === 1 ? '50vh' : '100vh';
}
}
