import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-pricing-strategy-controls',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class PricingStrategyControlsComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Pricing Strategy and Controls involve designing and implementing pricing models that align with product goals, market positioning, and competitive dynamics. This process includes selecting pricing methods, defining discount structures, and ensuring consistency across internal catalogues and sales configurations. A well-defined pricing strategy enables effective monetization, supports market competitiveness, and drives customer satisfaction by delivering perceived value.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves pricing strategies and ensures alignment with product and business objectives."
  },
  {
    "name": "Growth Team",
    "description": "Develops pricing strategies, executes pricing analysis, and monitors performance metrics."
  },
  {
    "name": "Product Marketing",
    "description": "Communicates pricing models, updates catalogues, and supports sales enablement."
  },
  {
    "name": "Customer Success Team",
    "description": "Provides feedback on customer pricing concerns and supports discounting initiatives."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Developing Pricing Structure",
            "description": {
              "Objective": [
                "Establish a pricing framework that reflects product value and aligns with competitive positioning."
              ],
              "Activity": [
                "Conduct price sensitivity analysis to determine optimal price points.",
                "Evaluate and select pricing models (e.g., subscription, pay-as-you-go, tiered pricing).",
                "Analyze competitor pricing and market benchmarks."
              ],
              "Outcome": [
                "A comprehensive pricing structure tailored to product and market needs."
              ]
            }
          },
          {
            "title": "Defining Discount and Bundling Tactics",
            "description": {
              "Objective": [
                "Create discounting and bundling strategies to enhance sales effectiveness and customer retention."
              ],
              "Activity": [
                "Develop discount matrices based on volume, tenure, or customer segment.",
                "Define bundling options that maximize perceived value (e.g., feature sets, product tiers).",
                "Simulate financial impact of discounting strategies on revenue and profitability."
              ],
              "Outcome": [
                "A flexible discount structure and bundling tactics aligned with business objectives."
              ]
            }
          },
          {
            "title": "Internal Positioning and Controls",
            "description": {
              "Objective": [
                "Ensure consistent application of pricing strategies across teams and systems."
              ],
              "Activity": [
                "Update internal catalogues and CPQ (Configure Price Quote) tools to reflect new pricing structures.",
                "Train sales and customer success teams on pricing and discount policies.",
                "Set up approval workflows to maintain pricing governance."
              ],
              "Outcome": [
                "An updated pricing framework embedded within internal systems and understood by stakeholders."
              ]
            }
          },
          {
            "title": "Monitoring and Adjustments",
            "description": {
              "Objective": [
                "Continuously monitor the effectiveness of pricing strategies and adapt as needed."
              ],
              "Activity": [
                "Analyze customer feedback and sales data to assess pricing effectiveness.",
                "Conduct regular pricing reviews to ensure alignment with market changes.",
                "Update discount policies and pricing structures based on insights."
              ],
              "Outcome": [
                "An adaptive pricing strategy that evolves with business and market dynamics."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Pricing Structure Document', description: 'Defines the pricing model, tiers, and feature sets.'
          },
          {title:'Discount Matrix', description: 'Outlines the discount levels based on criteria like volume or segment.'
          },
          {title:'Catalogue Update', description: 'Ensures internal tools and sales materials reflect the latest pricing structure.'
          },
          {title:'CPQ Configurations', description: 'Updates in Configure Price Quote systems for automated quoting.'
          },
          {title:'Approval Workflows', description: 'Governance processes for discounting and pricing changes.'
          }
        ]
      }
    }
  ];
}