import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import data from './../../../assets/data/sunburst.json';
import sideBarMetaData from './../../../assets/data/sidebarmeta.json';
import { CommonModule } from '@angular/common';
import { THEME } from 'src/app/app.constants';
interface BandData {
  radius: number;
  label: string;
}
@Component({
  selector: 'pet-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrl: './sunburst.component.scss'
})
export class SunburstComponent implements OnInit, OnDestroy {
  public DOMSelector: string = '';
  public width: number;
  public height: number;
  public radius: number;
  public color = d3.scaleOrdinal<string, string, string>();
  public sunBurstData: any;
  public hierarchy: d3.HierarchyNode<any>;
  public root: d3.HierarchyRectangularNode<unknown> & { current?: d3.HierarchyRectangularNode<unknown> };
  svg: d3.Selection<SVGGElement, any, any, any>;
  path: d3.Selection<d3.BaseType | SVGPathElement, d3.HierarchyRectangularNode<unknown> & { current?: d3.HierarchyRectangularNode<unknown>; }, SVGGElement, undefined>;
  label: d3.Selection<d3.BaseType | SVGTextElement, d3.HierarchyRectangularNode<unknown> & { current?: d3.HierarchyRectangularNode<unknown>; }, SVGGElement, undefined>;
  arc: d3.Arc<any, d3.DefaultArcObject>;
  backIconSVGString: string = `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" viewBox="0 0 16 16">
  <circle cx="8" cy="8" r="8" fill="white"/>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" fill="black"/>
</svg>
`;
  homeIconSVGString: string = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="100" height="100">
  <!-- Circle with black fill -->
  <circle cx="288" cy="256" r="256" fill="black" />
  <!-- House SVG path with white color, scaled down and positioned at the center -->
  <path d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" fill="white" transform="scale(0.55, 0.55) translate(230, 150)" />
</svg>




`;
  backButton: d3.Selection<d3.BaseType, any, any, any>;
  summaryMenuData: any;
  parent: d3.Selection<SVGGElement, {}, any, any>;
  public backIconWidth = 55;
  public backIconHeight = 55;

  constructor(private cdr: ChangeDetectorRef) { }
  // HostListener to handle window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setChartDimensions();
    this.updateChart(); // Recalculate and update the chart
  }
  ngOnDestroy(): void {
    d3.select(`${this.DOMSelector} svg`).remove();
  }
  ngOnInit(): void {
    this.setChartDimensions();
    this.summaryMenuData = JSON.parse(JSON.stringify(sideBarMetaData))['flare'];
    this.cdr.detectChanges();
    this.sunBurstData = JSON.parse(JSON.stringify(data));
    this.DOMSelector = `#sunBurstChart`;

    d3.select(`${this.DOMSelector} svg`).remove();
    const container = d3.select(this.DOMSelector) as d3.Selection<SVGSVGElement, any, any, any>;
    // this.width = 800;
    // this.height = this.width;
    // this.radius = this.width / 6;
    this.svg = container
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.height)
      .attr("viewBox", [-this.width / 2.5, -this.height / 2.1, this.width, this.width])
      .append('g')
      .style('font', '12.5px sans-serif');
    this.initChart();

  }
  setChartDimensions(): void {
    // Adjust width and height based on window size
    this.width = window.innerWidth * 0.45; // 80% of the window width
    this.height = window.innerHeight * 0.90; // Keep it square-shaped
    this.radius = this.width / 6.75; // Adjust the radius accordingly
  }
  // Update the chart when the window is resized
  updateChart(): void {
    // Remove and re-add the sunburst paths with updated radius, width, and height
    d3.select(`${this.DOMSelector} svg`).remove();
    const container = d3.select(this.DOMSelector) as d3.Selection<SVGSVGElement, any, any, any>;
    this.svg = container
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.height)
      .attr("viewBox", [-this.width / 2, -this.height / 2, this.width, this.width])
      .append('g')
      .style('font', '12.5px sans-serif');
    this.initChart();
  }
  initChart() {
    //custom colors are defined here
    const customColors = {
      "Strategy & Vision": "#0A2647",  // Deep navy blue
      "Discovery & Validation": "#144272",  // Rich deep blue
      "Roadmap & Planning": "#277DA1",  // Teal blue
      "Development & Product Readiness": "#1982C4",  // Bright professional blue
      "Release & Operational Readiness": "#2C74B3",  // Muted blue
      "Go-to-Market & Scaling": "#2E5077"  // Blue-gray
    };

    this.color = d3.scaleOrdinal<string>()
      .domain(Object.keys(customColors))
      .range(Object.values(customColors));
    const customOrder = [
      "Strategy & Vision",
      "Discovery & Validation",
      "Roadmap & Planning",
      "Development & Product Readiness",
      "Release & Operational Readiness",
      "Go-to-Market & Scaling"
    ];
    this.hierarchy = d3.hierarchy(this.sunBurstData)
      .sum((d) => d.value)
      .sort((a, b) => {
        const aIndex = customOrder.indexOf(a.data.name);
        const bIndex = customOrder.indexOf(b.data.name);

        // If both items are in the customOrder list, sort based on the index
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        }
        // If one item is in the custom order, put it first
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;
        // Otherwise, don't sort
        return 0;
      });
    // this.hierarchy = d3.hierarchy(this.sunBurstData)
    //   .sum(d => d.value)
    //   .sort((a, b) => (b && b.value && a && a.value) ? b.value - a.value : 0);

    this.root = d3.partition().size([2 * Math.PI, this.hierarchy.height + 1])(this.hierarchy);
    this.root.each(d => (d.current = d));
    // Create the SVG container.
    this.arc = d3.arc()
      .startAngle((d: any) => d.x0)
      .endAngle((d: any) => d.x1)
      .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(this.radius * 1.5)
      .innerRadius((d: any) => d.y0 * this.radius)
      .outerRadius((d: any) => Math.max(d.y0 * this.radius, d.y1 * this.radius - 1));
    this.parent = this.svg.append('g')
      .datum({})
      .attr('pointer-events', 'all')
      .on('click', clicked);
    this.path = this.svg.append("g")
      .selectAll("path")
      .data(this.root.descendants().slice(1))
      .join("path")
      .attr("fill", (d: any) => { while (d && d.depth > 1) d = d.parent; return d ? this.color(d.data.name) : ''; })
      .attr("fill-opacity", d => this.arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
      .attr("pointer-events", d => this.arcVisible(d.current) ? "auto" : "none")
      .attr("d", (d: any) => this.arc(d.current));
    this.label = this.svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(this.root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.5em")
      .attr("fill-opacity", d => +this.labelVisible(d.current))
      .attr("transform", d => this.labelTransform(d.current))
      .each(function (d: any) {
        const label = d3.select(this);
        wrapSVGText(label, d.data.name, d, this);
      })
      .style('font-size', '11.5px')
      .style('fill', THEME === 'light' ? '#000' : '#fff');
    // Create two bands  
    // Calculate the inner and outer radii for first band
    const innerRadiusFirst = this.radius * 0.8;
    const outerRadiusFirst = this.radius * 1;
    const middleRadiusFirst = this.radius * 0.9;
    const bandWidthFirst = outerRadiusFirst - innerRadiusFirst;
    // Add the bands (circle outlines).
    this.svg.append('g')
      .selectAll<SVGPathElement, BandData>('path.band') // Use the BandData type
      .data([
        { radius: innerRadiusFirst, label: 'Inner Band' },
        { radius: outerRadiusFirst, label: 'Outer Band' }
      ])
      .join('path')
      .attr('class', 'band')
      .attr('fill', 'none')
      .attr('stroke', '#000') // Band color
      .attr('stroke-width', 1)
      .attr('d', (d: BandData) => {
        const arcGenerator = d3.arc<BandData>()
          .startAngle(0)
          .endAngle(2 * Math.PI)
          .innerRadius(d.radius)
          .outerRadius(d.radius);

        return arcGenerator(d); // Generate the path data
      });
    //middle band
    this.svg.append('g')
      .selectAll<SVGPathElement, BandData>('path.middle-band') // Use the BandData type
      .data([
        { radius: middleRadiusFirst, label: 'middle Band' }
      ])
      .join('path')
      .attr('class', 'middle-band')
      .attr('fill', 'none')
      .attr('stroke', 'white') // Band color
      .attr('stroke-width', 24.5)
      .attr('d', (d: BandData) => {
        const arcGenerator = d3.arc<BandData>()
          .startAngle(0)
          .endAngle(2 * Math.PI)
          .innerRadius(d.radius)
          .outerRadius(d.radius);

        return arcGenerator(d); // Generate the path data
      });
    const newradiusFirst = (innerRadiusFirst + bandWidthFirst) / 2 + this.width / 17.5;
    const pathIdFirst = 'band-label-path';

    // Define the path for the left-hand parabola (semi-circle)
    this.svg.append('defs')
      .append('path')
      .attr('id', pathIdFirst)
      .attr('d', `M ${-newradiusFirst},0 A ${newradiusFirst} ${newradiusFirst} 0 0 1 ${newradiusFirst} 0`)
      .style('fill', 'yellow')
      .style('stroke', 'none');
    // Append the text and use the textPath to curve the text
    this.svg.append('g')
      .selectAll('text.band-label')
      .data([
        { label: 'LIFECYCLE STAGES', yOffset: 0 }
      ])
      .join('text')
      .attr('class', 'band-label')
      .attr('id', 'lifecycle-stages-text')
      .style('font-size', '12px')
      .style('fill', '#000') // Text color
      .append('textPath')
      .attr('xlink:href', `#${pathIdFirst}`)
      .attr('startOffset', '50%') // Adjust text position along the path
      .attr('text-anchor', 'middle') // Center the text on the path
      .text((d) => d.label);


    // Calculate the inner and outer radii for second band
    const innerRadiusSec = this.radius * 1.854;
    const midRadiusSec = this.radius * 1.95;
    const outerRadiusSec = this.radius * 2.04;
    const bandWidthSec = outerRadiusSec - innerRadiusSec;
    // Add the first band (circle outlines).
    this.svg.append('g')
      .selectAll<SVGPathElement, BandData>('path.inner-band') // Use the BandData type
      .data([
        { radius: innerRadiusSec, label: 'Inner Band' }
      ])
      .join('path')
      .attr('class', 'inner-band')
      .attr('fill', 'none')
      .attr('stroke', '#000') // Band color
      .attr('stroke-width', 1)
      .attr('d', (d: BandData) => {
        const arcGenerator = d3.arc<BandData>()
          .startAngle(0)
          .endAngle(2 * Math.PI)
          .innerRadius(d.radius)
          .outerRadius(d.radius);

        return arcGenerator(d); // Generate the path data
      });

    //middle band
    this.svg.append('g')
      .selectAll<SVGPathElement, BandData>('path.middle-band-sec') // Use the BandData type
      .data([{ radius: midRadiusSec, label: 'path.middle-band-sec' }])
      .join('path')
      .attr('class', 'middle-band-sec')
      .attr('fill', 'none')
      .attr('stroke', 'white') // Band color
      .attr('stroke-width', 24.5)
      .attr('d', d3.arc<BandData>()
        .startAngle(0)
        .endAngle(2 * Math.PI)
        .innerRadius(d => d.radius)
        .outerRadius(d => d.radius)
      );
    // Add the Second band (circle outlines).
    this.svg.append('g')
      .selectAll<SVGPathElement, BandData>('path.outer-band') // Use the BandData type
      .data([
        { radius: outerRadiusSec, label: 'Outer Band' }
      ])
      .join('path')
      .attr('class', 'outer-band')
      .attr('fill', 'none')
      .attr('stroke', '#000') // Band color
      .attr('stroke-width', 1)
      .attr('d', (d: BandData) => {
        const arcGenerator = d3.arc<BandData>()
          .startAngle(0)
          .endAngle(2 * Math.PI)
          .innerRadius(d.radius)
          .outerRadius(d.radius);

        return arcGenerator(d); // Generate the path data
      });
    const newradiusSec = (innerRadiusSec + bandWidthSec) / 2 + this.width / 7.5;
    const pathIdSec = 'band-label-path-second';
    // Add a new text section to display the current value
    this.svg.append('g')
      .attr('class', 'current-value-group')
      .attr('id', 'current-value-group')
      .append('text')
      .attr('class', 'current-value-text')
      .attr('id', 'current-value-text')
      .attr('x', 0)
      .attr('y', -50)
      .attr('text-anchor', 'middle')
      .style('font-size', '10px')
      .style('fill', THEME === 'light' ? '#000' : '#fff')
      .each(function () {
        const textElement = d3.select(this);
        wrapSVGText(textElement, 'Current Value:', {}, this);
      });


    // Define the path for the left-hand parabola (semi-circle)
    this.svg.append('defs')
      .append('path')
      .attr('id', pathIdSec)
      .attr('d', `M ${-newradiusSec},0 A ${newradiusSec} ${newradiusSec} 0 0 1 ${newradiusSec} 0`)
      .style('fill', 'yellow')
      .style('stroke', 'none');
    // Append the text and use the textPath to curve the text
    this.svg.append('g')
      .selectAll('text.band-label')
      .data([
        { label: 'ADAPT ACTIVITIES', yOffset: 0 }
      ])
      .join('text')
      .attr('class', 'band-label')
      .attr('id', 'adapt-activities-text')
      .style('font-size', '12px')
      .style('fill', '#000') // Text color
      .append('textPath')
      .attr('xlink:href', `#${pathIdSec}`)
      .attr('startOffset', '50%') // Adjust text position along the path
      .attr('text-anchor', 'middle') // Center the text on the path
      .text((d) => d.label);


    //back button
    const backIcon = this.parent
      .append('image')
      .attr(
        'xlink:href',
        'data:image/svg+xml,' + encodeURIComponent(this.backIconSVGString)
      )
      .attr('width', this.backIconWidth)
      .attr('height', this.backIconHeight)
      .attr('x', -this.backIconWidth / 2)
      .attr('y', -this.backIconHeight / 2)
      .style('cursor', 'pointer')
      .style('color', 'white');


    //home button
    // const homeIcon = this.parent
    // .append('image')
    // .attr(
    //   'xlink:href',
    //   'data:image/svg+xml,' + encodeURIComponent(this.homeIconSVGString)
    // )
    // .attr('width', this.backIconWidth)
    // .attr('height', this.backIconHeight)
    // .attr('x', -this.backIconWidth / 2)
    // .attr('y', -this.backIconHeight / 2)
    // .style('cursor', 'pointer')
    // .style('color', 'white');
    // const backText = this.parent
    //   .append('text')
    //   .text('Back')
    //   .attr('text-anchor', 'middle')
    //   .attr('dy', this.backIconHeight / 2 + 20);

    this.path.filter((d: any) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked)

    const format = d3.format(",d");
    this.path.append("title")
      .text((d: any) => `${d.ancestors().map((d: any) => d.data.name).reverse().join("/")}\n${format(d.value)}`);

    this.parent.on('click', null);
    //click function
    let newThis = this;
    hideBack();
    function clicked(event: any, p: any) {
      if (p.depth === 0 || p.depth === 1) {
        console.log('works');

        newThis.parent.datum(p.parent || newThis.root);

        newThis.root.each(
          (d: any) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
        );

        const t = newThis.svg.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if newThis transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        newThis.path
          .transition(t as any)
          .tween('data', (d: any) => {
            const i = d3.interpolate(d.current, d.target);
            return (t) => (d.current = i(t));
          })
          // .filter(function (d:any) {
          //   return +newThis.getAttribute('fill-opacity') || newThis.arcVisible(d.target);
          // })
          .attr('fill-opacity', (d: any) =>
            newThis.arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
          )
          .attr('pointer-events', (d: any) => (newThis.arcVisible(d.target) ? 'auto' : 'none'))

          .attrTween('d', (d) => () => newThis.arc(d?.current as any) || '');

        newThis.label
          // .filter(function (d:any) {
          //   return +newThis.getAttribute('fill-opacity') || newThis.labelVisible(d.target);
          // })
          .transition(t as any)
          .attr('fill-opacity', (d: any) => +newThis.labelVisible(d.target))
          .attrTween('transform', (d) => () => newThis.labelTransform(d.current, newThis.root.depth));
        if (p === newThis.root) {
          hideBack(p.data.name, p.depth);
        } else {
          showBack(p.data.name, p.depth);
        }

      }
      let summarySection: string = p && p?.data ? p.data.name : 'flare';
      changeSummaryData(summarySection);
    }
    function changeSummaryData(dataName: string) {
      newThis.summaryMenuData = JSON.parse(JSON.stringify(sideBarMetaData))[dataName];
      newThis.cdr.detectChanges();
    }
    function hideBack(dataName?: string, depth?: number) {
      newThis.parent
        .select('image')
        .attr(
          'xlink:href',
          newThis.root.parent !== null
            ? ''
            : 'data:image/svg+xml,' + encodeURIComponent(newThis.homeIconSVGString)
        );
      newThis.parent.select('text').text(newThis.root.parent !== null ? '' : 'Back');
      newThis?.parent.select('image').style('cursor', 'pointer');
      newThis?.parent.select('text').style('cursor', 'pointer');
      newThis?.parent.on('click', clicked);
      newThis?.svg.selectAll('#current-value-text').style('visibility', 'hidden');
      newThis?.svg.selectAll('path.inner-band').style('visibility', 'visible');
      newThis?.svg.selectAll('path.middle-band-sec').style('visibility', 'visible');
      newThis?.svg.selectAll('path.outer-band').style('visibility', 'visible');
      newThis?.svg.select('#adapt-activities-text').style('visibility', 'visible');
      newThis.svg.select('#adapt-activities-text')
        .select('textPath') // Select the textPath inside the text element
        .text(depth === 1 ? 'APPROVAL GATE' : 'ADAPT ACTIVITIES');

      if (dataName) {
        let setName = dataName === 'flare' ? 'LIFECYCLE STAGES' : dataName;
        newThis?.svg.select('#lifecycle-stages-text')
          .select('textPath') // Select the textPath inside the text element
          .text(setName);
        if (dataName === 'flare') {
          newThis.svg.select('#lifecycle-stages-text').style('cursor', 'default');
          newThis.svg.select('#lifecycle-stages-text')
            .on('mouseover', null)
            .on('mouseout', null)
            .style('fill', '#000');

          newThis.svg.select('#lifecycle-stages-text').on('click', null);
        }
      }
    }
    function showBack(dataName: string, depth?: number) {
      newThis.parent
        .select('image')
        .attr(
          'xlink:href',
          newThis.root.parent !== null
            ? ''
            : 'data:image/svg+xml,' + encodeURIComponent(newThis.backIconSVGString)
        );
      newThis.parent.select('text').text(newThis.root.parent !== null ? '' : 'Back');
      newThis.parent.select('image').style('cursor', 'pointer');
      newThis.parent.select('text').style('cursor', 'pointer');

      newThis?.svg.selectAll('#current-value-text').style('visibility', 'visible');
      newThis.svg.select('#current-value-text')
        .text(`${dataName}`);
      newThis.svg.select('#current-value-text').style('visibility', 'hidden');
      newThis.parent.on('click', clicked);
      newThis?.svg.selectAll('path.band').style('visibility', 'visible');
      newThis?.svg.selectAll('path.middle-band').style('visibility', 'visible');
      newThis.svg.select('#lifecycle-stages-text').style('visibility', 'visible');
      if (depth && depth === 1) {
        newThis.svg.select('#lifecycle-stages-text').style('cursor', 'pointer');
        newThis.svg.select('#lifecycle-stages-text')
          .on('mouseover', function () {
            d3.select(this).style('fill', 'dodgerblue');
          })
          .on('mouseout', function () {
            d3.select(this).style('fill', '#000');
          });
        newThis.svg.select('#lifecycle-stages-text').on('click', () => changeSummaryData(dataName));
      }
      newThis.svg.selectAll('path.inner-band').style('visibility', 'visible');
      newThis.svg.selectAll('path.outer-band').style('visibility', 'visible');
      newThis?.svg.selectAll('path.middle-band-sec').style('visibility', 'visible');
      newThis.svg.select('#adapt-activities-text').style('visibility', 'visible');
      newThis.svg.select('#adapt-activities-text')
        .select('textPath') // Select the textPath inside the text element
        .text(depth === 1 ? 'APPROVAL GATE' : 'ADAPT ACTIVITIES');
      // Update the text for #lifecycle-stages-text
      newThis.svg.select('#lifecycle-stages-text')
        .select('textPath') // Select the textPath inside the text element
        .text(depth === 1 ? dataName.toUpperCase() + ' ACTIVITIES' : 'LIFECYCLE STAGES');
    }
    function wrapSVGText(text: any, textContent: any, d: any, wrapThis: any) {

      const words = textContent.split(' ');
      let line: any[] = [];
      const lineHeight = 1;
      const maxLength = 16.85;

      let tspan = text
        .append('tspan')
        .attr('x', 0)
        .attr('y', 0)
        .attr('dx', '0em')
        .attr('dy', '0em')

      words.forEach((word: any) => {
        line.push(word);
        tspan.text(line.join(' '));

        const textLength = tspan.node().getComputedTextLength();
        const arcWidth = (d.x1 - d.x0) * wrapThis.radius;

        if (textLength > arcWidth || tspan.text().length > maxLength) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('dy', `${lineHeight}em`)
            .text(line.join(' '))
        }
      });
    }
  }
  public arcVisible(d: any) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }
  public labelVisible(d: any) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }
  public labelTransform(d: any, depth?: number) {

    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * this.radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;

  }

}

