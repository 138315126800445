import { Component } from '@angular/core';

@Component({
  selector: 'pet-roadmap',
  template: `
    <pet-page-template [data]='pageData' [status]='"Draft"'></pet-page-template>
  `,
  styles: []
})

export class RoadmapComponent {
  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'ADAPT and Insights Portal Roadmap',
        subText: 'The roadmap for the ADAPT Framework and the Insights Portal outlines our planned developments and strategic milestones within the Product Ecosystem & Transformation space. This roadmap ensures transparency and alignment across all stakeholders, enabling focused efforts on delivering impactful updates. Stay tuned as we refine and share our upcoming initiatives for these critical tools.'
      }
    }
  ];
}
