import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-release-management',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ReleaseManagementComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Release Process provides a structured approach to ensure product readiness through rigorous testing, validation, and alignment with stakeholders. It includes preparatory steps, internal and external validation stages, and controlled deployment to production. Each phase integrates best practices to identify and mitigate risks early, ensuring a smooth and successful release.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Feature Technical Lead",
    "description": "Ensures technical readiness and oversees testing activities in staging and pre-production environments."
  },
  {
    "name": "Feature Test Lead",
    "description": "Manages the execution of test plans and coordinates findings during staging and UAT phases."
  },
  {
    "name": "Release Manager",
    "description": "Manages the release process and oversees deployments across all environments."
  },
  {
    "name": "Product Manager/Owner",
    "description": "Validates product readiness and aligns functionality with customer expectations and business objectives."
  },
  {
    "name": "Delivery Teams",
    "description": "Support deployments, review findings, and collaborate on addressing any blockers during the release cycle."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Provide insights during UAT and support customer communication post-deployment."
  },
  {
    "name": "Engineering Manager",
    "description": "Ensures the development team adheres to quality standards and addresses technical issues promptly."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Development Environment",
            "description": {
              "Objective": [
                "Build and validate the product within the development environment to ensure functionality, quality, and compliance."
              ],
              "Activity": [
                "Develop or fix software and conduct unit testing.",
                "Perform static code analysis and Mend scans to identify vulnerabilities.",
                "Execute functional, integration, and performance testing.",
                "Prepare initial test reports and release notes."
              ],
              "Outcome": [
                "A validated and high-quality codebase ready for staging deployment."
              ]
            }
          },
          {
            "title": "Pre-UAT Setup",
            "description": {
              "Objective": [
                "Ensure readiness for user acceptance testing by setting up all necessary prerequisites."
              ],
              "Activity": [
                "Confirm requirements from the discovery process.",
                "Finalize the UAT schedule with customers or partners.",
                "Identify and onboard necessary resources from KDI and customer teams.",
                "Agree on joint test scripts and ensure their availability.",
                "Validate the availability of test data and the readiness of the UAT environment."
              ],
              "Outcome": [
                "Prepared and aligned stakeholders with all prerequisites in place for successful UAT."
              ]
            }
          },
          {
            "title": "Staging Environment",
            "description": {
              "Objective": [
                "Validate the product in a staging environment to ensure readiness for UAT."
              ],
              "Activity": [
                "Deploy the release to staging.",
                "Conduct sanity and end-to-end (E2E) testing, updating the E2E test suite as necessary.",
                "Execute performance testing and create threat modeling reports (if applicable).",
                "Generate customer-facing release notes.",
                "Obtain 'E2E Start Gate' approval to move to the UAT phase."
              ],
              "Outcome": [
                "A thoroughly validated product ready for external testing in UAT."
              ]
            }
          },
          {
            "title": "Pre-Production Environment (UAT)",
            "description": {
              "Objective": [
                "Collaborate with customers or partners to validate the product’s usability, functionality, and business alignment."
              ],
              "Activity": [
                "Deploy functionality to the UAT environment.",
                "Conduct sanity testing and execute agreed UAT test scripts.",
                "Document findings and categorize them by type (bug or improvement) and severity.",
                "Iterate to address agreed-upon feedback.",
                "Secure customer approval to proceed to production."
              ],
              "Outcome": [
                "Customer-approved product ready for production deployment."
              ]
            }
          },
          {
            "title": "Production Environment",
            "description": {
              "Objective": [
                "Deploy the validated product to the production environment for general availability."
              ],
              "Activity": [
                "Deploy the release to production.",
                "Perform sanity testing post-deployment.",
                "Notify customers via status page, email, or in-app notifications.",
                "Complete launch formalities and initiate post-launch monitoring."
              ],
              "Outcome": [
                "Successful deployment to production with all stakeholders informed."
              ]
            }
          }
        ]
      }
    },    
    {type:'attachment',
      value:{
        title:'Media/Attachments (2)',
        items:[
          {src:'assets/media/images/ADAPT-PreUAT-UAT.png', description:'Pre-UAT/UAT Process'},
          {src:'assets/media/images/ADAPT-ReleaseProcess.png', description:'Release Process'}
        ]
      }
    }, 
    
  ];
}