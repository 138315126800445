import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-market-customer-analysis',
  template:
    `
  <pet-page-template [data]='pageData' [additionalContent]='additionalContent' [status]='"Draft"'></pet-page-template>
  `,
  styles: ``
})

export class MarketCustomerAnalysisComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Market and customer analysis forms the foundation for building products that align with real customer needs and market demands. It involves understanding customer behaviors, trends, and challenges, as well as identifying opportunities for growth. This process integrates qualitative and quantitative research to guide strategic decision-making and product development.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves budget. Approves findings and outcomes to ensure they align with the strategic direction of the organization."
  },
  {
      "name": "Product Manager",
      "description": "Responsible for developing research brief and agreeing research outcomes. Responsible for coordinating market and customer analysis activities and ensuring insights align with product strategy."
    },
    {
      "name": "Customer Success Team",
      "description": "Provides direct feedback from customer interactions to enrich analysis with real-world data. Provides help with identifying appropriate customers/prospects that would be suitable for research interviews or workshops."
    },
    {
      "name": "Growth Team",
      "description": "Contributes sales data and insights on market opportunities to guide analysis. Provides help with identifying appropriate customers/prospects that would be suitable for research interviews or workshops."
    },
    {
      "name": "Strategy Team (CSO)",
      "description": "Ensures the analysis aligns with broader corporate strategy and market trends."
    },
    {
      "name": "Product Design",
      "description": "Uses insights from analysis to support persona mapping and validate user-centered design principles Where appropriate helps with facilitating customer interviews/workshops."
    }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Customer Trends and Market Trends Analysis",
            "description": {
              "Objective": ["Identify customer pain points, behaviors, and emerging market trends."],
              "Activity": [
                "Organize and conduct customer advisory panels to collect direct feedback.",
                "Facilitate user group meetings to engage with end-users and capture detailed feedback.",
                "Analyze market data and internal reports to identify trends and challenges.",
                "Host internal advisory panels to leverage in-house expertise for strategic planning."
              ],
              "Outcome": ["A comprehensive understanding of customer needs and market trends, enabling data-driven product strategy decisions."]
            }
          },
          {
            "title": "Product Analytics",
            "description": {
              "Objective": ["Identify customer product usage  behaviors in terms of frequency and range of functions available that helps determine success or otherwise."],
              "Activity": [
                "Identify product usage metrics that provide insights into usage, frequency and likely benefits.",
                "Identify product usage metrics that would support higher levels of customer value.",
                "Identify product usage metrics that are linked to commercial approaches, e.g. transactional pricing."
              ],
              "Outcome": ["A comprehensive understanding of customer product usage metrics that support internal success and customer value. Use as insights for continued investment."]
            }
          },
          {
            "title": "Persona Development",
            "description": {
              "Objective": ["Create detailed customer personas to represent key user and buying personas."],
              "Activity": [
                "Collect demographic, behavioral, and contextual data from customer interactions.",
                "Convert these insights into ‘semi-factual’ representation of a typical customer persona.",
                "Develop persona maps highlighting motivations, goals, and challenges.",
                "Refine personas through continuous feedback and alignment with customer data."
              ],
              "Outcome": ["Detailed personas that serve as reference points for product design, development and marketing communication."]
            }
          },
          {
            "title": "Business Case Development",
            "description": {
              "Objective": ["Develop a robust business case to prioritize opportunities."],
              "Activity": [
                "Use a standardized business case template to document risks, benefits, and ROI.",
                "Incorporate data from customer panels and market analysis to validate the case.",
                "Align business case insights with strategic goals and roadmap priorities."
              ],
              "Outcome": ["Validated business cases that justify resource allocation and strategic focus."]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Example 1', description:
            {
              'Objective': ['Using customer panels, a product team discovers a common pain point in usability for a specific feature. This insight informs a roadmap adjustment to prioritize UX improvements.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Example 2', description:
            {
              'Objective': ['Conducting market trend analysis identifies a growing demand for sustainability features in a SaaS product. This insight leads to the development of eco-friendly functionalities.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Tools / Resources / Templates',
        items: [
          { id: 1, text: 'Market research platforms (e.g., Qualtrics, SurveyMonkey).' },
          { id: 2, text: 'Collaboration tools (e.g., LucidSpark) for mapping personas.' },
          { id: 3, text: 'Business case template.' },
          { id: 4, text: 'Persona mapping framework.' }
        ]
      }
    },
    
  ];

  public additionalContent = [
    {
      type: 'tips',
      title: 'Tips',
      value: 'Market and customer analysis thrives on collaboration—engage cross-functional teams to gather diverse perspectives and uncover deeper insights.'
    },
    {
      type: 'attachments',
      title: 'Templates',
      value: [
        {
          name: 'Customer Advisory Panel UCI',
          url: 'assets/media/templates/2409 Customer Advisory Panel UCI.pptx'
        },
        {
          name: 'Kano Simple Template',
          url: 'assets/media/templates/2409 Kano Simple Template.xlsx'
        },
        {
          name: 'Price Sensitivity Template',
          url: 'assets/media/templates/2409 Price Sensitivity Template.xlsx'
        },
        {
          name: 'JTBD Simple Opportunity Landscape',
          url: 'assets/media/templates/2409 JTBD Simple Opportunity Landscape.xlsx'
        }
      ]
    }, 
    {
      type: 'optionalContent',
      title: 'Benefits and Repercussions',
      value: [{
        key: 'Benefits',
        data: ['Encourages holistic strategies by integrating viewpoints from product, sales, and customer success teams.', 'Strengthens customer relationships by addressing needs with precision and empathy.']
      },
      {
        key: 'Repercussions',
        data: ['Limited collaboration can result in narrow insights and overlooked opportunities.', 'Ignoring diverse input risks creating products that fail to resonate with broader customer segments.']
      }]
    }
  ];
}