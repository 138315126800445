import { Component, Input } from '@angular/core';
import { THEME } from 'src/app/app.constants';

@Component({
  selector: 'pet-page-template',
  templateUrl: './page-template.component.html',
  styleUrl: './page-template.component.scss'
})
export class PageTemplateComponent {
  @Input() data: any;
  @Input() additionalContent?: any;
  @Input() status?: string;
  codeBlockIndex:number = 0;
  currentStep: number = 0; 
  public colors = ['#2C74B3', '#144272', '#1982C4', '#277DA1', '#2E5077', 
    '#081d36', '#2c3e50',  '#205292'];

public theme = THEME;
    getType(item: any): string {
      return typeof(item);
    }
    getArray(arrayData:{ [key: string]: [] } , key:any): string[] {
      return arrayData[key];
    }
    getKeys(objectData: { [key: string]: string[] }): string[] {
      return Object.keys(objectData);
    }
    setIndex(index:number){
      this.codeBlockIndex = index;
    }

    copyCode() {
      const codeBlock = document.getElementById('codeBlock'+this.codeBlockIndex);
      const textArea = document.createElement('textarea');
      if (codeBlock && codeBlock.textContent) {
        textArea.value = codeBlock.textContent;
      }
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      
      let copyBtn:Element | null = document.querySelector('.copy-btn');
      if(copyBtn){
      copyBtn.innerHTML = '<i class="bi bi-check"></i> Copied!';
      copyBtn.classList.remove('btn-outline-secondary');
      copyBtn.classList.add('btn-success');
      }
      
      setTimeout(() => {
        if(copyBtn){
          copyBtn.innerHTML = '<i class="bi bi-clipboard"></i> Copy';
          copyBtn.classList.remove('btn-success');
          copyBtn.classList.add('btn-outline-secondary');
        }
      }, 2000);
}

nextStep() {
  this.currentStep++;
}
resetStepper() {
  this.currentStep = 0;
}


    }
