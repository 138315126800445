import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-business-case',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class BusinessCaseComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Business Case process provides a structured justification for investing in a product, whether new or existing. It is supported by data-driven market assessments and a realistic financial model that includes financial and operational forecasts. This process ensures that investment decisions are well-founded, transparent, and aligned with strategic goals, incorporating inputs and buy-in from relevant stakeholders.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Chief Product Officer (CPO)",
    "description": "Reviews and approves the business case to ensure strategic alignment."
  },
  {
    "name": "Product VP",
    "description": "Leads the development and ensures cross-functional collaboration on the business case."
  },
  {
    "name": "Product Manager",
    "description": "Gathers and analyzes market data, collaborates with teams, and drafts the business case."
  },
  {
    "name": "Architect",
    "description": "Provides technical input and feasibility assessments for the proposed investment."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Offer customer insights and market trends to inform the business case."
  },
  {
    "name": "Finance Team",
    "description": "Validates financial models and ensures the feasibility of forecasts."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Market Assessment",
            "description": {
              "Objective": [
                "Collect data and insights to evaluate market opportunities and challenges."
              ],
              "Activity": [
                "Conduct market analysis using techniques like SWOT and PESTLE.",
                "Assess customer needs, competitor positioning, and industry trends."
              ],
              "Outcome": [
                "A clear understanding of market dynamics and opportunities."
              ]
            }
          },
          {
            "title": "Financial Modeling",
            "description": {
              "Objective": [
                "Develop realistic financial forecasts to support investment decisions."
              ],
              "Activity": [
                "Create financial models covering revenue projections, operational costs, and ROI.",
                "Incorporate assumptions and sensitivity analysis for risk evaluation."
              ],
              "Outcome": [
                "Comprehensive financial models supporting the business case."
              ]
            }
          },
          {
            "title": "Cross-Functional Collaboration",
            "description": {
              "Objective": [
                "Engage relevant teams to validate assumptions and align on the business case."
              ],
              "Activity": [
                "Collaborate with architects, customer success, growth, and finance teams.",
                "Iteratively refine the business case based on feedback."
              ],
              "Outcome": [
                "Validated and refined business case with cross-functional buy-in."
              ]
            }
          },
          {
            "title": "Drafting the Business Case",
            "description": {
              "Objective": [
                "Compile all data, analysis, and stakeholder inputs into a comprehensive document."
              ],
              "Activity": [
                "Use the Business Case Template to document findings and recommendations.",
                "Outline the investment rationale, expected outcomes, and resource requirements."
              ],
              "Outcome": [
                "A complete and compelling business case document ready for review."
              ]
            }
          },
          {
            "title": "Stakeholder Review and Approval",
            "description": {
              "Objective": [
                "Present the business case to decision-makers for final approval."
              ],
              "Activity": [
                "Engage the CPO and other key stakeholders for a review session.",
                "Incorporate feedback and secure approval for the proposed investment."
              ],
              "Outcome": [
                "Approved business case ready for execution."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Key Outcomes',
        items:[
          {title:'Clear investment decisions based on the "Start, Stop, Continue" framework.', description: ''
          },
          {title:'Comprehensive documentation of business justification and expected outcomes.', description: ''
          },
          {title:'Stakeholder alignment and approval for proposed investments.', description: ''
          }
        ]
      }
    }
  ];
}