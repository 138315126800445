import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-strategic-roadmap',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class StrategicRoadmapComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Strategic Roadmap process involves defining and communicating the product’s future direction and priorities. It serves as a guide for internal and external stakeholders, aligning strategic goals with development efforts while remaining adaptable to changes in the business environment. This roadmap reflects investment allocations, high-level priorities, and development progress, ensuring transparency and alignment across teams.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Chief Product Officer (CPO)",
    "description": "Approves the strategic roadmap, ensuring alignment with corporate goals."
  },
  {
    "name": "Product VP",
    "description": "Leads roadmap development and ensures cross-functional collaboration."
  },
  {
    "name": "Product Manager",
    "description": "Drafts the roadmap, gathers input, and incorporates feedback from stakeholders."
  },
  {
    "name": "Architect",
    "description": "Provides technical feasibility and alignment with architectural strategy."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Offer insights into customer needs and market trends to inform roadmap priorities."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Define Strategic Objectives",
            "description": {
              "Objective": [
                "Identify the overarching goals and vision for the product."
              ],
              "Activity": [
                "Collaborate with the CPO, Product VP, and other leaders to set strategic objectives.",
                "Analyze market trends, customer needs, and competitive positioning."
              ],
              "Outcome": [
                "Clear and actionable strategic objectives that guide roadmap development."
              ]
            }
          },
          {
            "title": "Investment Allocation and Prioritization",
            "description": {
              "Objective": [
                "Allocate resources to ensure alignment with strategic objectives."
              ],
              "Activity": [
                "Use techniques like STP (Strategic Targeting Process) to determine investment areas.",
                "Prioritize initiatives based on potential ROI, customer impact, and feasibility."
              ],
              "Outcome": [
                "Documented investment allocations and a prioritized list of initiatives."
              ]
            }
          },
          {
            "title": "Draft High-Level Roadmap",
            "description": {
              "Objective": [
                "Create a high-level roadmap reflecting strategic priorities and development progress."
              ],
              "Activity": [
                "Utilize the Roadmap Template to draft a comprehensive visual representation.",
                "Incorporate estimates and timelines for major initiatives."
              ],
              "Outcome": [
                "A draft roadmap that communicates priorities and aligns teams."
              ]
            }
          },
          {
            "title": "Stakeholder Review and Feedback",
            "description": {
              "Objective": [
                "Ensure the roadmap aligns with stakeholder expectations and addresses strategic goals."
              ],
              "Activity": [
                "Present the roadmap to internal and external stakeholders for feedback.",
                "Incorporate suggestions to refine the roadmap."
              ],
              "Outcome": [
                "An approved and refined strategic roadmap ready for publication."
              ]
            }
          },
          {
            "title": "Publication and Maintenance",
            "description": {
              "Objective": [
                "Communicate the roadmap and keep it up-to-date with development progress."
              ],
              "Activity": [
                "Publish the roadmap on internal and external platforms for visibility.",
                "Regularly update the roadmap to reflect changes in priorities or progress."
              ],
              "Outcome": [
                "A published and continuously updated strategic roadmap accessible to stakeholders."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Key Outcomes',
        items:[
          {title:'Transparent investment allocations.', description: ''
          },
          {title:'High-level priorities and estimates.', description: ''
          },
          {title:'Alignment between strategic goals and development efforts.', description: ''
          }
        ]
      }
    }
  ];
}