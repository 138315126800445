import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-deployment-planning-support',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class DeploymentPlanningSupportComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Deployment planning and support are crucial for ensuring the smooth transition of a product or update from development to the live environment. This process involves defining a comprehensive rollout plan, securing necessary resources, and coordinating efforts across development, operations, and support teams. Deployment planning minimizes disruption and ensures readiness, while proactive support addresses any issues promptly post-launch. Together, these efforts maintain system stability and customer satisfaction, contributing to a successful product release.'
      }
    },
    {
      type: 'tileItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Delivery Team",
    "description": "Ensures the deployment process aligns with business objectives and oversees its execution."
  },
  {
    "name": "Customer Success Team",
    "description": "Coordinates post-deployment support and facilitates communication with customers."
  },
  {
    "name": "Development Team",
    "description": "Provides technical expertise during deployment and addresses any technical issues that arise."
  },
  {
    "name": "Release Manager",
    "description": "Manages the overall deployment timeline, readiness reviews, and stakeholder alignment."
  },
  {
    "name": "Product Marketing Team",
    "description": "Prepares customer communication materials and aligns messaging with the deployment."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Deployment Planning",
            "description": {
              "Objective": [
                "Create a detailed deployment plan that outlines all necessary steps for a successful rollout."
              ],
              "Activity": [
                "Identify key stakeholders and align on deployment objectives.",
                "Define a step-by-step deployment plan, including timelines and resource allocation.",
                "Perform a readiness review, ensuring all pre-deployment tasks (e.g., staging validation, test completion) are done.",
                "Communicate the deployment plan to all involved teams and stakeholders."
              ],
              "Outcome": [
                "A well-documented deployment plan that ensures all teams are aligned and prepared for the release."
              ]
            }
          },
          {
            "title": "Execution of Rollout",
            "description": {
              "Objective": [
                "Execute the deployment plan with precision and monitor the process to ensure success."
              ],
              "Activity": [
                "Deploy the product or update to the live environment according to the defined plan.",
                "Monitor the deployment process for any deviations or issues.",
                "Validate that all functionalities are working as intended post-deployment.",
                "Communicate the deployment status to stakeholders."
              ],
              "Outcome": [
                "A successfully deployed product with minimal disruption and high system stability."
              ]
            }
          },
          {
            "title": "Post-Deployment Support (Hypercare)",
            "description": {
              "Objective": [
                "Provide immediate and proactive support to address any post-launch issues."
              ],
              "Activity": [
                "Set up a dedicated support team to monitor and resolve issues during the hypercare period.",
                "Gather feedback from customers and internal teams to identify improvement areas.",
                "Document and prioritize post-launch issues for resolution.",
                "Maintain clear communication with stakeholders about issue resolution progress."
              ],
              "Outcome": [
                "Quick resolution of issues, reduced downtime, and enhanced customer satisfaction."
              ]
            }
          },
          {
            "title": "Handover and Documentation",
            "description": {
              "Objective": [
                "Ensure seamless knowledge transfer to long-term support and operational teams."
              ],
              "Activity": [
                "Conduct a formal handover to support and operations teams.",
                "Share deployment documentation, including lessons learned and troubleshooting guides.",
                "Ensure all post-deployment updates are reflected in the knowledge base."
              ],
              "Outcome": [
                "A well-prepared support team and updated documentation to facilitate ongoing maintenance."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Best Practices',
        items:[
          {title:'Clear Communication', description: 'Ensure all stakeholders understand their roles and responsibilities.'
          },
          {title:'Proactive Monitoring', description: 'Use monitoring tools to track deployment progress and identify issues early.'
          },
          {title:'Customer-Centric Support', description: 'Gather and prioritize customer feedback during the hypercare phase.'
          },
          {title:'Iterative Improvement', description: 'Document lessons learned and update deployment processes for continuous improvement.'
          }
        ]
      }
    }
  ];
}