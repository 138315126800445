import { Component } from '@angular/core';

@Component({
  selector: 'pet-onboarding-guide',
  template: `
    <pet-page-template [data]='pageData'  [status]='"Draft"'></pet-page-template>
  `,
  styles: []
})

export class OnboardingGuideComponent {

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The ADAPT Onboarding Guide is your go-to resource for getting started with the ADAPT framework. It provides a structured approach to help new team members understand the principles, processes, and tools critical to the ADAPT journey. The Onboarding Guide is currently under development. We are committed to creating a user-friendly and comprehensive guide to help you navigate the ADAPT framework effectively. Please stay tuned for updates as we finalize this resource.'
      }
    }
  ];
}
